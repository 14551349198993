import { colorSystem } from "goi_common";

const title = "부산 울산 경남 장례 안내 포털";

export const parlorRegion = {
  defaultMetaData: {
    pageOgTitle: title + " :: 부울경 지역 장례식장, 장지, 기초생활수급자 장례 혜택",
    pageDescription: `${title} 장례식장을 대상으로 한 비용 비교: 1일 동안 100명의 조문객이 참석할 때의 빈소 비용 및 추가 비용에 대한 정보 부산, 울산, 경남, 경상 및 주변 지역 장례식장 추천도 함께 포함 `,
    pageKeywords:
      "장례, 장례식, 가족장, 무빈소장례, 장례지도사, 장례식장, 장례 비용, 상조, 상조 회사, 상조회, 상조가입, 상조추천, 후불제상조, 장례상조, 상조서비스, 장례식 절차, 장례 절차, 임종, 고인, 빈소, 임종",
    pageOgDescription: `${title} 장례식장 상세 비용, 가장 저렴한 ${title} 장례식장 비용 정보, 주변 장례식장과 비교하기.`,
    pageOgImage: "",
  },

  layout: {
    headTitle: title,
    layoutStyle: {
      background: "white",
      borderBottom: "none",
      color: "black",
      height: "60px",
    },
    ctaButtonStyle: {
      border: `1px solid ${colorSystem.yellow.c400}`,
      background: colorSystem.yellow.c400,
      color: "white",
    },
  },

  targetSidos: ["21", "26", "38"],

  regionColor: colorSystem.yellow,

  bestParlorIds: [40, 73, 317, 433, 280],

  baseUrl: "https://www.gyeongsang-portal.co.kr",
};
